.legend {
    padding: 0.5rem 2rem;
    margin: 3.5rem auto 1rem;
    border: 1px solid #eee;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .option {
        margin: 0 0.75rem;
        font-weight: 300;
        font-size: 0.9rem;
        line-height: 1;
        padding: 0.5rem 0;
        white-space: nowrap;

        .indicator {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 5px;
            margin-bottom: 1px;
        }

        &.online {
            .indicator {
                background: $up;
            }
        }
        &.offline {
            .indicator {
                background: $down;
            }
        }
        &.maintenance {
            .indicator {
                background: $moderate;
            }
        }
        &.unknown {
            .indicator {
                background: $unknown;
            }
        }
    }
}
