*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Ubuntu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    text-rendering: optimizeLegibility;
    line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: 600;
    line-height: 1;
}

h1 {
    font-size: 3rem;
}

a {
    text-decoration: none;
}
