.home {
    padding: 3rem 2rem;
    text-align: center;

    h1 {
        margin-bottom: 3rem;
    }

    @media only screen and (max-width: 600px) {
        padding-top: 5rem;

        h1 {
            z-index: 1;
            position: fixed;
            display: flex;
            align-items: center;
            text-align: left;
            top: 0;
            left: 0;
            right: 0;
            padding-left: 2rem;
            margin-bottom: inherit;
            height: 55px;
            background: #202020;
            color: #fff;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 500;
        }
    }
}
