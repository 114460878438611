.statusblock {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1.5rem;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    transform: scale(1);
    transition: transform 0.4s ease;

    @media (hover: hover) {
        &:hover {
            transform: scale(0.975);
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 300;
        font-size: 0.875rem;
        color: #707070;

        & > *:first-child {
            text-align: left;
        }

        & > *:last-child {
            text-align: right;
        }
    }

    .header {
        margin-bottom: 0.75rem;

        .name {
            font-size: 1.2rem;
            color: #101010;
            font-weight: 400;
        }

        .status {
            display: block;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            background: $unknown;

            &.up {
                background: $up;
            }

            &.down {
                background: $down;
            }

            &.maintenance {
                background: $moderate;
            }
        }
    }
}
